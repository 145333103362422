import {
  removeEmptyKeys,
  mergeSimilarActivities,
  getBattleSolveTime,
} from 'src/utils'
import {
  BattleGameModes,
  battles,
  BattleStatuses,
} from '@cssbattle/shared/battles'
import {VersusRoomStatuses} from '@cssbattle/shared/versus'
import levels from '@cssbattle/shared/levels'
import {
  query,
  collection,
  where,
  doc,
  getDoc,
  getDocs,
  getCountFromServer,
  orderBy,
  limit,
  serverTimestamp,
  deleteDoc,
  updateDoc,
  addDoc,
  onSnapshot,
  setDoc,
  or,
  increment,
} from 'firebase/firestore'
import {deleteObject, getStorage, ref} from 'firebase/storage'
import '../firebase-init'
import {PROJECT_ID, db, firebaseApp} from '../firebase-init'

const documentCache = {}

// if (false) {
//   const _doc = db.doc
//   const _collection = db.collection
//   db.doc = (...args) => {
//     console.log('doc()', args)
//     return _doc.apply(db, args)
//   }
//   db.collection = (...args) => {
//     console.log('collection()', args)
//     return _collection.apply(db, args)
//   }
// }

const env = process.env.NEXT_PUBLIC_ENV || process.env.NODE_ENV
export const API =
  env === 'development'
    ? 'https://us-central1-cssbattle-756.cloudfunctions.net'
    : // ? 'http://localhost:5001/cssbattle-756/us-central1'
      'https://cssbattle.dev/api'
export const NEXT_API = '/api'

const BATTLES_COLLECTION = 'battles'
const BATTLE_SCORES_COLLECTION = 'battleScores'
const TARGET_SCORES_COLLECTION = 'targetScores'
const PURCHASES_COLLECTION = 'purchases'
const SUBSCRIPTIONS_COLLECTION = 'subscriptions'
const EVENTS_COLLECTION = 'events'
const SUBMISSIONS_COLLECTION = 'submissions'
const FOLLOWING_COLLECTION = 'following'
const PLAYERS_COLLECTION = 'players'
const SCORES_COLLECTION = 'scores'
const LEVELS_COLLECTION = 'levels'
const EDITOR_PLUGINS_COLLECTION = 'editorPlugins'
const COURSE_COLLECTION = 'course'
const TOP_SUBMISSIONS_COLLECTION = 'topSubmissions'
const NOTIFICATIONS_COLLECTION = 'notifications'
const GLOBAL_COLLECTION = 'global'
const TARGET_STATS_COLLECTION = 'targetStats'
const STREAKS_COLLECTION = 'streaks'
const VERSUS_COLLECTION = 'versus'
const SETTINGS_COLLECTION = 'settings'

const LEVEL_SCORE_IN_TIME_ATTACK = 600

let lastReadSaveCount = 0
let readSaveTimeout

// function saveRead() {
//   db.doc(`global/misc`).update({
//     reads: increment(lastReadSaveCount),
//   })
//   lastReadSaveCount = 0
// }
function readUp(count) {
  return false
  /*  window.readCount = window.readCount || 0
  window.readCount += count
  lastReadSaveCount += count
  if (window.location.href.match(/localhost/)) {
    // console.log('readCount', window.readCount)
  }
  clearTimeout(readSaveTimeout)
  readSaveTimeout = setTimeout(saveRead, 2000) */
}

function getLevelLeaderboardQuery(levelId, isCSSLeaderboard = true) {
  return query(
    collection(db, TARGET_SCORES_COLLECTION),
    where('levelId', '==', levelId),
    orderBy(isCSSLeaderboard ? 'score' : 'overall.score', 'desc'),
    orderBy(isCSSLeaderboard ? 'lastUpdated' : 'overall.lastUpdated')
  )
}
function getDocumentFromCache(docId) {
  if (documentCache[docId]) {
    const retVal = {
      exists: () => true,
      id: docId,
      data: () => {
        return documentCache[docId]
      },
    }
    return retVal
  }
}
/**
 * Converts a firestore query snapshot into native array
 * @param {snapshot} querySnapshot Snapshot object returned by a firestore query
 */
function getArrayFromQuerySnapshot(querySnapshot) {
  const arr = []
  querySnapshot.forEach((doc) => {
    // doc.data() has to be after doc.id because docs can have `id` key in them which
    // should override the explicit `id` being set
    arr.push({
      id: doc.id,
      ...doc.data(),
    })
    documentCache[doc.id] = doc.data()
  })
  readUp(arr.length)
  return arr
}

function getDocument(queryPromise, {withoutId = false, useCache = false} = {}) {
  const cacheVal = useCache && getDocumentFromCache(queryPromise.id)
  // if (cacheVal) {
  //   console.log('getting', queryPromise._delegate.path, ' from cache')
  // }

  return (cacheVal ? Promise.resolve(cacheVal) : getDoc(queryPromise)).then(
    (doc) => {
      if (doc.exists()) {
        readUp(1)
        if (withoutId) return doc.data()
        // doc.data() has to be after doc.id because docs can have `id` key in them which
        // should override the explicit `id` being set
        return {id: doc.id, ...doc.data()}
      }
      return null
    }
  )
}
function queryDocuments(queryPromise) {
  return getDocs(queryPromise).then(getArrayFromQuerySnapshot)
}

function sortLeaderBoard(leaderboard, isCSSLeaderboard = true) {
  return leaderboard.sort((a, b) => {
    if (isCSSLeaderboard) {
      if (a.score === b.score) return a.lastUpdated - b.lastUpdated
      return b.score - a.score
    } else {
      if (a.overall?.score === b.overall?.score)
        return a.overall?.lastUpdated - b.overall?.lastUpdated
      return (b.overall?.score || 0) - (a.overall?.score || 0)
    }
  })
}

/**
 * Gets the entry for passed user from `scores` collection
 * @param {string} userId User ID
 */
function getUserScoreData(userId) {
  return getDoc(doc(db, `${SCORES_COLLECTION}/${userId}`))
    .then((doc) => {
      if (doc.exists()) {
        readUp(1)
        return doc.data()
      } else {
        return undefined
      }
    })
    .catch(() => {
      return undefined
    })
}

export async function getUserScoresForLevels({userId, levelIds}) {
  function getScoreForLevel(levelId) {
    return queryDocuments(
      query(
        collection(db, TARGET_SCORES_COLLECTION),
        where('levelId', '==', levelId),
        where('userId', '==', userId)
      )
    ).then((arr) => arr[0])
  }
  return Promise.all(levelIds.filter(Boolean).map(getScoreForLevel)).then(
    (scores) => {
      return scores.filter(Boolean).reduce((obj, current) => {
        obj[current.levelId] = current
        return obj
      }, {})
    }
  )
}

/**
 * Fetces the user's friend list (array of friend IDs)
 * @param {string} userId User ID
 */
function fetchUserFriendList(userId) {
  return queryDocuments(
    query(
      collection(db, FOLLOWING_COLLECTION),
      where('followerId', '==', userId)
    )
  ).then((results) => results.map((entry) => entry.followedId))
}

/**
 * this fn takes in a list of data and a key that represents a player ID.
 * it then fetches respective player profiles and inserts them into the data.
 * This modifies the original array.
 */
function insertPlayerInfo(list, playerIdKey) {
  // FIXME: replace later with lodash.get
  const keyParts = playerIdKey.split('.')
  const playerIds = list.map((entry) =>
    keyParts.length === 1 ? entry[playerIdKey] : entry[keyParts[0]][keyParts[1]]
  )
  return Promise.all(playerIds.map(getPlayerProfileCached)).then(
    (playerProfiles) => {
      list.forEach((entry, index) => {
        entry.playerProfile = playerProfiles[index]
      })
    }
  )
}

/**
 * Returns submissions for any level.
 * Reads: depends on number of submissions. Max seen: 150
 */
export async function getUserSubmissionsForLevel({userId, levelId, count}) {
  let q = query(
    collection(db, SUBMISSIONS_COLLECTION),
    where('userId', '==', userId),
    where('levelId', '==', levelId),
    orderBy('timestamp', 'desc')
  )

  if (count) {
    q = query(q, limit(count))
  }

  return queryDocuments(q).catch(function (error) {
    console.log('Error getting document:', error)
    return 0
  })
}

export async function getUserSubmissionFromId(submissionId) {
  return getDocument(doc(db, `${SUBMISSIONS_COLLECTION}/${submissionId}`))
}

/**
 * Returns top submissions for an open level.
 * Reads: depends on number of submissions. Max reads: 10 + 10 + 10 = 30
 */
export async function getTopSubmissionsForLevel(levelId) {
  async function getSubmission(submissionId) {
    return getDocument(doc(db, `${SUBMISSIONS_COLLECTION}/${submissionId}`))
  }
  const leaderboard = await getLevelLeaderboard({levelId})
  const submissions = await Promise.all(
    leaderboard.map((targetScore) => getSubmission(targetScore.submissionId))
  )

  return leaderboard.map((entry, i) => ({...submissions[i], ...entry}))
}

/**
 * Returns top submissions for a daily target. this is different that usual levels because
 * the top solutions are precomputed and store. We just need to fetch them and hydrate
 * with player profiles.
 * Reads: depends on number of submissions. Max reads: 10 + 10 + 10 = 30
 */
export async function getTopSubmissionsForDailyLevel(levelId) {
  const data = await getDocument(
    doc(db, `${TOP_SUBMISSIONS_COLLECTION}/${levelId}`)
  )
  if (data) {
    await insertPlayerInfo(data.submissions, 'userId')
    return data.submissions
  }
  return []
}

function cache(func) {
  const cache = new Map()

  return async function (...args) {
    const cacheKey = JSON.stringify(args)
    // console.log('fetching - ', cacheKey)

    if (cache.has(cacheKey)) {
      // console.log('Returning cached result for ', cacheKey)
      return cache.get(cacheKey)
    }

    const result = func(...args)
    cache.set(cacheKey, result)
    // console.log('cache set for ', cacheKey, result)
    return result
  }
}

/**
 * Fetches player profile.
 * Reads: 1
 * @param {string} playerId player ID
 */
export async function getPlayerProfile(playerId, isForcedFetch) {
  if (
    typeof window === 'undefined' ||
    window.location.href.match(/localhost/) ||
    isForcedFetch
  ) {
    return getDocument(doc(db, PLAYERS_COLLECTION, playerId)).then((doc) => {
      if (doc) {
        doc = {...doc, displayName: doc.displayName || 'User'}
      }
      return doc
    })
  } else {
    const response = await fetch(`${NEXT_API}/playerProfile?userId=${playerId}`)
    const data = await response.json()
    return data
  }
}

export const getPlayerProfileCached = cache(getPlayerProfile)

/**
 * fetches a player with passed username.
 * Reads: 1
 * @param {string} username player username
 */
export function getPlayerProfileFromUsername(username) {
  const queryRef = query(
    collection(db, PLAYERS_COLLECTION),
    where('username', '==', username)
  )

  return queryDocuments(queryRef).then((arr) => {
    if (!arr.length) return
    return {id: arr[0].id, ...arr[0], displayName: arr[0].displayName || 'User'}
  })
}

/**
 * Fetches the global leaderboard. Queries from `scores` & `players`
 * collection to creation composite results.
 * Reads: count * 2 (20)
 * @param {Number} count How many top players to fetch
 */
export async function getGlobalLeaderboard(count = 10) {
  const q = query(
    collection(db, SCORES_COLLECTION),
    orderBy('score', 'desc'),
    orderBy('lastUpdated'),
    limit(count)
  )

  return getDocs(q)
    .then(async (querySnapshot) => {
      const arr = getArrayFromQuerySnapshot(querySnapshot)

      if (!arr.length) return []

      await insertPlayerInfo(arr, 'id')
      return arr.map((item, index) => ({
        ...item,
        rank: index + 1,
        playedCount: Object.keys(item.levels).length,
      }))
    })
    .catch(function (error) {
      console.log('Error getting document:', error)
      return 0
    })
}

/**
 * Returns friend-only global leaderboard
 * Reads: friend count
 * @param {object} levelId, userId
 */
export async function getFriendsGlobalLeaderboard({userId}) {
  // get list of friend Ids
  const friendsIds = await fetchUserFriendList(userId)

  if (!friendsIds.length) {
    return []
  }

  // Push current user's Id into friends list
  friendsIds.push(userId)

  const promises = friendsIds.map((friendId) =>
    Promise.all([getPlayerProfile(friendId), getUserScoreData(friendId)])
  )

  return Promise.all(promises).then((resolvedArray) => {
    resolvedArray = resolvedArray.map(([profile, scoreData], index) => ({
      ...scoreData,
      id: profile.id,
      playedCount: scoreData ? Object.keys(scoreData.levels).length : 0,
      playerProfile: profile,
    }))

    // Sort
    return sortLeaderBoard(resolvedArray)
  })
}

/**
 * Returns a particular level's top 10 player score and profile information.
 * Reads: 20
 * @param {number} levelId Level ID
 */
export async function getLevelLeaderboard({
  levelId,
  numberOfPlayers = 10,
  isCSSLeaderboard = true,
}) {
  const q = query(
    getLevelLeaderboardQuery(levelId, isCSSLeaderboard),
    limit(numberOfPlayers)
  )

  return queryDocuments(q)
    .then(async (arr) => {
      if (!arr.length) return []

      await insertPlayerInfo(arr, 'userId')

      return arr.map((item, index) => ({
        ...item,
        rank: index + 1,
      }))
    })
    .catch(function (error) {
      console.log('Error getting document:', error)
      return 0
    })
}

/**
 * Returns a particular level's friend-only leaderboard
 * Reads: 20
 * @param {object} levelId, userId
 */
export async function getFriendsLevelLeaderboard({
  userId,
  levelId,
  isCSSLeaderboard = true,
}) {
  // get list of friend Ids
  const friendsIds = await fetchUserFriendList(userId)

  if (!friendsIds.length) {
    return []
  }

  // Push current user's Id into friends list
  friendsIds.push(userId)

  const promises = friendsIds.map((friendId) =>
    Promise.all([
      getPlayerProfile(friendId),
      getLevelHighScoreForPlayer({playerId: friendId, levelId}).then(
        (data) => data || {score: 0}
      ),
    ])
  )

  return Promise.all(promises).then((resolvedArray) => {
    resolvedArray = resolvedArray.map(([profile, scoreData], index) => ({
      ...scoreData,
      id: profile.id,
      playerProfile: profile,
    }))
    // Sort
    return sortLeaderBoard(resolvedArray, isCSSLeaderboard)
  })
}

/**
 * Returns a particular battle's top 10 player score and profile information.
 * Reads: numberOfPlayers * 3 (30)
 * @param {number} levelId Level ID
 */
export async function getBattleLeaderboard({
  battleId,
  numberOfPlayers = 10,
  isCSSLeaderboard = true,
  includePlayedCount = true,
}) {
  const q = query(
    collection(db, BATTLE_SCORES_COLLECTION),
    where('battleId', '==', battleId),
    orderBy(isCSSLeaderboard ? 'score' : 'overall.score', 'desc'),
    orderBy(isCSSLeaderboard ? 'lastUpdated' : 'overall.lastUpdated'),
    limit(numberOfPlayers)
  )

  return queryDocuments(q)
    .then(async (arr) => {
      if (!arr.length) {
        return []
      }
      const battle = await fetchBattle(battleId)

      const profilePromises = arr.map((score) =>
        Promise.all([
          getPlayerProfileCached(score.userId),
          includePlayedCount
            ? getUserScoresForLevels({
                userId: score.userId,
                levelIds: battle.levelIds,
              })
            : Promise.resolve({}),
        ])
      )
      return Promise.all(profilePromises).then((resolvedArray) => {
        // playedCount for "First to match" is calculated by divinding battle score by 600.
        // otherwise, a target played after battle also gets counted
        return resolvedArray.map(([profile, scoreData], index) => ({
          ...arr[index],
          timeTaken: getBattleSolveTime(battle, arr[index].lastUpdated),
          id: arr[index].userId,
          rank: index + 1,

          playedCount:
            battle.gameMode === BattleGameModes.FIRST_TO_MATCH
              ? (arr[index]?.score || 0) / LEVEL_SCORE_IN_TIME_ATTACK
              : battle.levelIds.filter((levelId) => scoreData[levelId]?.score)
                  .length,
          playerProfile: profile,
        }))
      })
    })
    .catch(function (error) {
      console.log('Error getting document:', error)
      return []
    })
}

/**
 * Fetches the leaderboard of players you follow. Queries from `scores` & `players`
 * collection to creation composite results.
 * Reads: num_of_friends * 4
 * @param {string} userId User for whom friends leaderboard is to be fetched
 */
export async function getFriendsBattleLeaderboard({
  userId,
  battleId,
  isCSSLeaderboard = true,
}) {
  const battle = await fetchBattle(battleId)

  // get list of friend Ids
  const friendsIds = await fetchUserFriendList(userId)

  if (!friendsIds.length) {
    return []
  }

  // Push current user's Id into friends list
  friendsIds.push(userId)

  const promises = friendsIds.map((friendId) =>
    Promise.all([
      getPlayerProfile(friendId),
      getUserScoreData(friendId),

      getDocs(
        query(
          collection(db, BATTLE_SCORES_COLLECTION),
          where('userId', '==', friendId),
          where('battleId', '==', battleId)
        )
      ).then((querySnapshot) => {
        const arr = getArrayFromQuerySnapshot(querySnapshot)
        return arr[0] || {score: 0}
      }),
    ])
  )

  return Promise.all(promises).then((resolvedArray) => {
    resolvedArray = resolvedArray.map(
      ([profile, scoreData, battleData], index) => ({
        ...battleData,
        id: profile.id,
        timeTaken: battleData.score
          ? getBattleSolveTime(battle, battleData.lastUpdated)
          : 0,
        playedCount: battle.levelIds.filter(
          (levelId) => scoreData.levels[levelId]
        ).length,
        playerProfile: profile,
      })
    )

    // Sort
    return sortLeaderBoard(resolvedArray, isCSSLeaderboard)
  })
}

/**
 * Fetches the streak leaderboard. Queries from `streaks`
 * @param {Number} count How many top players to fetch
 */
export async function getStreaksLeaderboard({
  useAllTimeStreak = false,
  count = 10,
}) {
  const key = useAllTimeStreak ? 'longestStreak' : 'streak.count'
  const q = query(
    collection(db, STREAKS_COLLECTION),
    where(key, '>', 0),
    orderBy(key, 'desc'),
    limit(count)
  )

  return queryDocuments(q)
    .then(async (arr) => {
      if (!arr.length) {
        return []
      }

      await insertPlayerInfo(arr, 'id')

      return arr.map((item, index) => ({
        ...item,
        rank: index + 1,
      }))
    })
    .catch(function (error) {
      console.log('Error getting document:', error)
      return 0
    })
}

/**
 * Returns friend-only streak leaderboard
 * @param {object} userId
 */
export async function getFriendsStreaksLeaderboard({userId, useAllTimeStreak}) {
  // get list of friend Ids
  const friendsIds = await fetchUserFriendList(userId)

  if (!friendsIds.length) {
    return []
  }

  // Push current user's Id into friends list
  friendsIds.push(userId)

  const promises = friendsIds.map((friendId) =>
    Promise.all([getPlayerProfile(friendId), getStreak(friendId)])
  )

  return Promise.all(promises).then((resolvedArray) => {
    resolvedArray = resolvedArray.map(([profile, streakData], index) => ({
      ...streakData,
      id: profile.id,
      // added key 'score', since we use function 'sortLeaderBoard' below, which sorts based on 'score', 'streakData' does not have score
      score:
        (useAllTimeStreak
          ? streakData?.longestStreak
          : streakData?.streak?.count) || 0,
      playerProfile: profile,
    }))

    // Sort
    return sortLeaderBoard(resolvedArray)
  })
}

/**
 * Returns a particular level's top player score and profile information.
 * Reads: 2
 * @param {number} levelId Level ID
 */
export async function getLevelStats(levelId) {
  const q = query(getLevelLeaderboardQuery(levelId), limit(1))

  return queryDocuments(q)
    .then((arr) => {
      if (!arr.length) return null
      const profilePromises = arr.map((score) => getPlayerProfile(score.userId))
      if (profilePromises.length) {
        return Promise.all(profilePromises).then((profiles) => {
          return profiles.map((profile, index) => ({
            levelId: levelId,
            highscore: {
              score: arr[index].score,
              codeSize: arr[index].codeSize,
              match: arr[index].match,
              playerProfile: profile,
            },
          }))[0]
        })
      }
      return null
    })
    .catch(function (error) {
      console.log('Error getting document:', error)
      return null
    })
}

/**
 * Returns top player score and profile information for passed levels.
 * Reads: 2 * levelIds.length
 * @param {number} levelIds List of Level IDs
 */
export async function getLevelsStats(levelIds) {
  const promises = levelIds.map((levelId) => getLevelStats(levelId))

  return Promise.all(promises)
}

/**
 * Returns a particular level's top 3 player scores.
 * Reads: 3
 * @param {number} levelId Level ID
 */
export async function getLevelTop3(levelId) {
  const q = query(getLevelLeaderboardQuery(levelId), limit(3))

  return queryDocuments(q).catch(function (error) {
    console.log('Error getting document:', error)
    return []
  })
}

/**
 * Returns the high score of a player for a given level.
 * Reads: 1
 * @param {object} param0 player's ID and level ID
 */
export async function getLevelHighScoreForPlayer({playerId, levelId}) {
  return getDocument(
    doc(db, `${TARGET_SCORES_COLLECTION}/${playerId}_${levelId}`),
    {withoutId: true}
  ).catch(() => {
    return
  })
}
export const getLevelHighScoreForPlayerCached = cache(
  getLevelHighScoreForPlayer
)

/**
 * Returns highscores for all levels on a given player.
 * Reads: 1
 * @param {object} param0 player's ID
 */
export async function getScoresForPlayer({playerId}) {
  return getDoc(doc(db, `${SCORES_COLLECTION}/${playerId}`))
    .then((doc) => {
      if (doc.exists()) {
        readUp(1)
        const data = doc.data()
        return data
      }
      return {}
    })
    .catch(() => {
      return {}
    })
}

export async function getAllHighScoresForPlayer({playerId}) {
  const playerScores = await getScoresForPlayer({playerId})
  return playerScores.levels || {}
}

export async function fetchRank({userId, levelId, battleId}) {
  var params = new URLSearchParams(
    removeEmptyKeys({
      userId,
      battleId,
      levelId,
    })
  )
  let data
  try {
    const result = await fetch(
      `https://us-central1-${PROJECT_ID}.cloudfunctions.net/getRank?${params}`
    )
    data = await result.json()
  } catch (e) {
    return null
  }
  return data
}

export function getGlobalStats() {
  return getDocument(doc(db, `${GLOBAL_COLLECTION}/stats`))
}

export function saveProfile(userId, data) {
  return updateDoc(doc(db, `${PLAYERS_COLLECTION}/${userId}`), data)
}

export async function saveUsername(user, username) {
  const token = await user.firebaseUser.getIdToken()
  return fetch(`${API}/saveUsername`, {
    method: 'post',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({
      token,
      username,
    }),
  })
    .then((response) => {
      if (!response.ok) {
        throw response
      }
      return response.text()
    })
    .catch((err) => {
      if (err instanceof Response) {
        throw err.text()
      }
    })
}

export async function isFollowingPlayer({followedId, followerId}) {
  return getDoc(
    doc(db, FOLLOWING_COLLECTION, `${followedId}_${followerId}`)
  ).then((doc) => doc.exists())
}
export async function followPlayer({followedId, followerId}) {
  return setDoc(doc(db, FOLLOWING_COLLECTION, `${followedId}_${followerId}`), {
    followedId,
    followerId,
    timestamp: serverTimestamp(),
  })
}
export async function unFollowPlayer({followedId, followerId}) {
  return deleteDoc(doc(db, FOLLOWING_COLLECTION, `${followedId}_${followerId}`))
}

/**
 * This fn sets up a listener on the given user's notifications
 * and calls the passed callback whenever new ones are detected realtime.
 * The return value is a function that unbinds the realtime listener.
 * @param {string} userId User to get notifications for
 * @param {Function} callback function to call when new notifications are found
 */
export function listenForNotifications(userId, callback) {
  return onSnapshot(
    query(
      collection(db, NOTIFICATIONS_COLLECTION),
      where('userId', '==', userId)
    ),
    (querySnapshot) => {
      const arr = getArrayFromQuerySnapshot(querySnapshot).map(
        (notification) => ({
          ...notification,
          // Convert seconds to milliseconds
          timestamp: notification.timestamp.seconds * 1000,
        })
      )
      arr.sort((a, b) => b.timestamp - a.timestamp)

      callback(arr)
    }
  )
}

/**
 * Returns submissions for any level.
 * Reads: depends on number of submissions. Max seen: 150
 */
export async function getEditorPlugins({userId}) {
  /* return [
        {title: 'Minify', code: 'function run (code) { return code+" minified"}'},
        {title: 'Unit fix', code: 'function run(code) { return code+"unit fixed"}'}
      ] */
  return getDocs(
    query(
      collection(db, EDITOR_PLUGINS_COLLECTION),
      where('userId', '==', userId)
    )
  )
    .then(getArrayFromQuerySnapshot)
    .catch(function (error) {
      console.log('Error getting document:', error)
      return 0
    })
}

export function saveEditorPlugin(plugin) {
  const data = {
    title: plugin.title,
    code: plugin.code,
    shouldAutoRun: plugin.shouldAutoRun || false,
    isStarred: plugin.isStarred || false,
  }
  if (plugin.id) {
    return updateDoc(doc(db, EDITOR_PLUGINS_COLLECTION, plugin.id), {
      ...data,
      updatedOn: serverTimestamp(),
    })
  } else {
    // First save
    return addDoc(collection(db, EDITOR_PLUGINS_COLLECTION), {
      ...data,
      userId: plugin.userId,
      createdOn: serverTimestamp(),
    }).then((docRef) => docRef.id)
  }
}

export function deleteEditorPlugin(plugin) {
  return deleteDoc(doc(db, EDITOR_PLUGINS_COLLECTION, plugin.id))
}

/**
 * This gets all the subscriptions for a given user. One's which user has
 * purchased or been gifted.
 * @param {string} userId user ID
 * @returns {Promise} resolves to an array of subscriptions
 */
export async function getLatestSubscriptions(userId) {
  const q = query(
    collection(db, SUBSCRIPTIONS_COLLECTION),
    or(where('userId', '==', userId), where('recieverId', '==', userId)),
    orderBy('timestamp', 'desc')
  )
  const events = await queryDocuments(q)
  await Promise.all(
    events
      .filter((e) => e.recieverId)
      .map((event) => {
        // check if this a receiver or the sender. We fetch the profile accordingly
        const isThisSender = userId === event.userId
        return getPlayerProfileCached(
          isThisSender ? event.recieverId : event.userId
        ).then((profile) => {
          event[isThisSender ? 'reciever' : 'sender'] = profile
        })
      })
  )
  if (!events.length) return []

  let latestSubscriptionForSelf = null
  let subs = []

  for (const event of events) {
    if (event.type === 'subscription_created') {
      if (event.recieverId) {
        subs.push(event)
      } else if (!latestSubscriptionForSelf) {
        latestSubscriptionForSelf = event
      }
    }
  }
  if (latestSubscriptionForSelf) {
    subs.push(latestSubscriptionForSelf)
  }
  // Sort gift subscription to bottom of list
  subs.sort((a, b) => {
    if (a.recieverId && !b.recieverId) return 1
    if (!a.recieverId && b.recieverId) return -1
    return 0
  })

  // Group events
  const groupedEvents = subs.map((sub) => {
    return {
      subscription: sub,
      payments: events.filter(
        (e) =>
          e.type === 'subscription_payment_succeeded' &&
          e.data.subscription_id === sub.data.subscription_id
      ),
      cancellation: events.filter(
        (e) =>
          e.type === 'subscription_cancelled' &&
          e.data.subscription_id === sub.data.subscription_id
      )[0],
    }
  })

  // console.log(groupedEvents)
  return groupedEvents
}

export async function getLastSubscription({userId}) {
  const lastPaymentRef = query(
    collection(db, SUBSCRIPTIONS_COLLECTION),
    where('userId', '==', userId),
    where('type', '==', 'subscription_payment_succeeded'),
    orderBy('timestamp', 'desc')
  )

  const lastSubscriptionRef = query(
    collection(db, SUBSCRIPTIONS_COLLECTION),
    where('userId', '==', userId),
    where('type', '==', 'subscription_created'),
    orderBy('timestamp', 'desc')
  )

  const cancellationRef = query(
    collection(db, SUBSCRIPTIONS_COLLECTION),
    where('userId', '==', userId),
    where('type', '==', 'subscription_cancelled'),
    orderBy('timestamp', 'desc')
  )

  const [lastSubscription, lastPayment, cancellation] = await Promise.all([
    queryDocuments(lastSubscriptionRef),
    queryDocuments(lastPaymentRef),
    queryDocuments(cancellationRef),
  ])
  return {
    lastSubscription: lastSubscription[0],
    lastPayment: lastPayment[0],
    lastCancellation: cancellation[0],
  }
}

export async function getActivityStream(battleId) {
  let q = query(collection(db, EVENTS_COLLECTION), orderBy('timestamp', 'desc'))

  if (battleId) {
    q = query(q, where('data.battleId', '==', battleId))
  }
  q = query(q, limit(20))

  return getDocs(q).then(async (querySnapshot) => {
    const arr = getArrayFromQuerySnapshot(querySnapshot)
    if (!arr.length) return arr

    mergeSimilarActivities(arr)

    await insertPlayerInfo(arr, 'data.userId')

    return arr.map((item, index) => ({
      ...item,
      timestamp: item.timestamp.seconds * 1000,
    }))
  })
}

export async function fetchBattle(battleId) {
  const battle = battles.find((battle) => battle.id === battleId)
  if (battle) {
    return battle
  }

  return getDocument(doc(db, BATTLES_COLLECTION, battleId))
}

export async function fetchBattleForLevelId(level) {
  const battle = level
    ? battles.find((battle) => battle.levelIds.includes(level.id))
    : null

  if (battle) return battle
  if (!level.battleId) return null
  // Cant do this because querying is disabled on battles collection
  // return queryDocuments(
  //   db.collection('battles').where('levelIds', 'array-contains', levelId)
  // ).then(arr => arr[0])

  return getDocument(doc(db, BATTLES_COLLECTION, level.battleId))
}

export async function fetchLevel(levelId) {
  if (!levelId) return {}

  // See if this is a local/official level
  const level = levels.find((level) => level.id === levelId)
  if (level) {
    return level
  }

  return getDocument(doc(db, LEVELS_COLLECTION, levelId), {
    useCache: true,
  })
}

/**
 * Returns the list of levels for the passed level Ids
 * @param {Array} levelIds List of level Ids
 */
export async function fetchLevels(levelIds) {
  const promises = levelIds.map((levelId) => fetchLevel(levelId))

  return Promise.all(promises)
}

export async function saveBattle({userId, battle, levels, oldLevels}) {
  const data = removeEmptyKeys({
    name: battle.name,
    description: battle.description,
    duration: battle.duration,
    status: battle.status,
    gameMode: battle.gameMode,
    levelIds: levels ? levels.map((level) => level.id) : undefined,
    isScheduled: battle.isScheduled,
    startDate: battle.startDate,
    endDate: battle.endDate,
  })
  console.table(data)

  if (data.isScheduled) {
    data.startDate = new Date(data.startDate)
  } else if (data.startDate) {
    data.startDate = serverTimestamp()
  } else {
    data.startDate = null // making the start date as null when isScheduled is false removes the start date timer (similar effect to deleting the key from database)
  }
  if (data.endDate) {
    data.endDate = serverTimestamp()
  }

  // Find levels that have changed now. The modified ones are deleted first,
  // before updating.
  if (levels && oldLevels && oldLevels.length) {
    const levelIdsToDelete = oldLevels
      .filter((oldLevel) => {
        // If it isn't present at all in new levels list
        if (!levels.find((l) => l.id === oldLevel.id)) {
          return true
        }
        return false
      })
      .map((level) => level.id)
    if (levelIdsToDelete.length) {
      const deleteLevelPromises = levelIdsToDelete.map((levelId) =>
        deleteLevel(levelId)
      )

      await Promise.all(deleteLevelPromises)
    }
  }

  const levelSavePromises = levels
    ? levels.map((level) =>
        saveLevel({
          userId,
          battleId: battle.id,
          level,
        })
      )
    : Promise.resolve()

  if (battle.id) {
    return Promise.all([
      updateDoc(doc(db, BATTLES_COLLECTION, battle.id), {
        ...data,
        updatedOn: serverTimestamp(),
      }),
      levelSavePromises,
    ])
  } else {
    // First save
    return addDoc(collection(db, BATTLES_COLLECTION), {
      ...data,
      createdBy: userId,
      status: BattleStatuses.UPCOMING,
      createdOn: serverTimestamp(),
      updatedOn: serverTimestamp(),
    }).then((docRef) => docRef.id)
  }
}

export async function deleteBattle({battleId, levelIds = []}) {
  const deleteLevelPromises = levelIds.map((levelId) => deleteLevel(levelId))
  // First delete the levels, bcoz if battle is deleted the levels won't be allowed to
  // delete.
  await Promise.all(deleteLevelPromises)
  return deleteDoc(doc(db, `${BATTLES_COLLECTION}/${battleId}`))
}

export function saveLevel({userId, battleId, level}) {
  const data = removeEmptyKeys({
    createdBy: userId,
    battleId,
    updatedOn: serverTimestamp(),
    ...level,
  })

  return setDoc(doc(db, LEVELS_COLLECTION, level.id), data)
}

export async function deleteLevel(levelId) {
  require('firebase/storage')

  const storageService = getStorage(firebaseApp)

  const level = await fetchLevel(levelId)
  const imageFilePath = decodeURIComponent(level.image.match(/user.*png/)[0])
  const storageRef = ref(storageService, imageFilePath)
  const storageRef2x = ref(
    storageService,
    imageFilePath.replace('.png', '@2x.png')
  )

  return Promise.all([
    deleteDoc(doc(db, `${LEVELS_COLLECTION}/${levelId}`)),
    deleteObject(storageRef),
    deleteObject(storageRef2x),
  ])
}

export function getCustomBattlesByUser(userId) {
  return queryDocuments(
    query(collection(db, BATTLES_COLLECTION), where('createdBy', '==', userId))
  )
}

export function getCustomBattlePurchases(userId) {
  return queryDocuments(
    query(
      collection(db, PURCHASES_COLLECTION),
      where('userId', '==', userId),
      where('type', '==', 'customBattle')
    )
  )
}

/**
 * This fn sets up a listener on the given user's custom battle
 * purchases which are unused
 * @param {string} userId User to get notifications for
 * @param {Function} callback function to call when new notifications are found
 */
export function listenForCustomBattlePurchases(userId, callback) {
  return onSnapshot(
    query(
      collection(db, PURCHASES_COLLECTION),

      where('userId', '==', userId),
      where('type', '==', 'customBattle'),
      where('isUsed', '==', false)
    ),
    (querySnapshot) => {
      const arr = getArrayFromQuerySnapshot(querySnapshot)
      callback(arr)
    }
  )
}

export async function getUserPlayedBattles(userId) {
  const battleScores = await queryDocuments(
    query(
      collection(db, BATTLE_SCORES_COLLECTION),
      where('userId', '==', userId)
    )
  )

  const battles = await Promise.all(
    battleScores.map((battleScore) => fetchBattle(battleScore.battleId))
  )

  // `battle` has to be after `battleScores[index]` because battleScores will have `id` key
  // in them which should  be overridden by `id` of `battle
  return battles.map((battle, index) => ({...battleScores[index], ...battle}))
}

export async function getCourseDetails(userId) {
  return getDocument(doc(db, `${COURSE_COLLECTION}/${userId}`))
}

export async function subscribeToCourseDetails(userId, callback) {
  return onSnapshot(doc(db, `${COURSE_COLLECTION}/${userId}`), (doc) => {
    callback(doc.data())
  })
}

export async function incrementCourseLevel({
  userId,
  courseName,
  levelId,
  hasCompleted,
}) {
  // console.log('incrementing course level', userId, courseName, levelId)

  const data = {
    level: levelId,
    lastUpdated: serverTimestamp(),
  }
  if (hasCompleted) {
    data.completedDate = serverTimestamp()
  }
  updateDoc(doc(db, `${COURSE_COLLECTION}/${userId}`), {
    [courseName]: data,
  })
}

function getCurrentDayUTCStartDate() {
  const currentLocalDate = new Date()
  const utcDate = new Date(
    currentLocalDate.toLocaleString('en-US', {timeZone: 'UTC'})
  )
  const startDate = new Date(
    Date.UTC(
      utcDate.getFullYear(),
      utcDate.getMonth(),
      utcDate.getDate(),
      0,
      0,
      0
    )
  )
  return startDate
}

async function getActiveLastNDailyTargets(n) {
  const targetStartDate = getCurrentDayUTCStartDate()

  const levels = await queryDocuments(
    query(
      collection(db, LEVELS_COLLECTION),
      where('type', '==', 'daily'),
      where('startDate', '<=', targetStartDate),
      orderBy('startDate', 'desc'),
      limit(n)
    )
  )

  return levels
}
export const getActiveLastNDailyTargetsCached = cache(
  getActiveLastNDailyTargets
)

export async function getLastNDailyTargets(n) {
  const levels = await queryDocuments(
    query(
      collection(db, LEVELS_COLLECTION),
      where('type', '==', 'daily'),
      orderBy('startDate', 'desc'),
      limit(n)
    )
  )

  return levels
}

export async function getDailyTargetsBetweenDates(startDate, endDate) {
  const levels = await queryDocuments(
    query(
      collection(db, LEVELS_COLLECTION),
      where('type', '==', 'daily'),
      orderBy('startDate', 'desc'),
      where('startDate', '>=', startDate),
      where('startDate', '<=', endDate)
    )
  )

  return levels
}
export const getDailyTargetsBetweenDatesCached = cache(
  getDailyTargetsBetweenDates
)

export async function getStreak(userId) {
  return getDocument(doc(db, STREAKS_COLLECTION, userId))
}

export async function getStreakCountRealtime(userId, callback) {
  return onSnapshot(doc(db, STREAKS_COLLECTION, userId), (doc) => {
    callback(doc.data())
    readUp(1)
  })
}

export function getTargetStatsForPlayer(userId) {
  return getDocument(doc(db, `${TARGET_STATS_COLLECTION}/${userId}`))
}

export function getGlobalTargetStats(levelId) {
  return getDocument(doc(db, `${TARGET_STATS_COLLECTION}/${levelId}`))
}

export async function getTotalPlayedCount(userId) {
  const playedTargetsCountQuery = query(
    collection(db, TARGET_SCORES_COLLECTION),
    where('userId', '==', userId)
  )
  const snapshot = await getCountFromServer(playedTargetsCountQuery)
  return snapshot.data().count
}

export function listenForPlayerChange(userId, callback) {
  return onSnapshot(doc(db, PLAYERS_COLLECTION, userId), (doc) =>
    callback(doc.data())
  )
}

export function getLastDailyTargetScoreForPlayer(userId) {
  const q = query(
    collection(db, TARGET_SCORES_COLLECTION),
    where('userId', '==', userId),
    where('type', '==', 'daily')
  )
  return queryDocuments(q).then((arr) => arr[0])
}

export async function getDaywiseSubmissionsChartData(userId) {
  const result = await fetch(
    `https://cssbattleapp.web.app/daywiseSubmissionsCount?userId=${userId}`
  )
  const data = await result.json()
  return data
}

export async function createVersusBattle({userId, versus}) {
  const data = removeEmptyKeys({
    roomCode: versus.roomCode,
    capacity: 2,
    players: [userId],
    isReady: {[userId]: false},
    settings: versus.settings,
  })

  // TODO: handle case when user has created some other 'active' room
  // ask user, if he wants to leave current room and create a new room?
  // this would delete the room from rooms collection OR mark it inactive.

  return addDoc(collection(db, VERSUS_COLLECTION), {
    ...data,
    createdBy: userId,
    status: VersusRoomStatuses.ACTIVE,
    createdOn: serverTimestamp(),
    updatedOn: serverTimestamp(),
  }).then((docRef) => docRef.id)
}

export async function getVersusRoomFromCode(roomCode) {
  const room = query(
    collection(db, VERSUS_COLLECTION),
    where('status', '==', VersusRoomStatuses.ACTIVE),
    where('roomCode', '==', roomCode),
    limit(1)
  )

  return queryDocuments(room).then((arr) => arr[0])
}

// TODO: shift the join room function to backend, to avoid hacking?

export async function joinVersusBattle({userId, roomCode}) {
  // case when user is in some other 'active' room
  const checkIfUserInActiveRoom = query(
    collection(db, VERSUS_COLLECTION),
    where('status', '==', VersusRoomStatuses.ACTIVE),
    where('players', 'array-contains', userId)
  )

  return (Promise.resolve([]) || queryDocuments(checkIfUserInActiveRoom)).then(
    (arr) => {
      if (false && arr.length) {
        // TODO: need to handle this by giving user prompt to leave room and join new one.
        return 'You are already in 1 room, leave it to join this one!'
      }

      // check all the active rooms, for room code match
      else {
        return getVersusRoomFromCode(roomCode).then((room) => {
          // case when room code not found
          if (!room) {
            return 'Room code invalid!'
          }

          // case when room is full
          if (room.players.length === room.capacity) {
            return 'Room is full!'
          }

          // case when room code matches
          else {
            // not allowing kicked player to join again!
            if (room.kickedPlayers && room.kickedPlayers.includes(userId)) {
              return 'You are restricted from entering this room.'
            }

            // update players in room
            updateDoc(doc(db, VERSUS_COLLECTION, room.id), {
              updatedOn: serverTimestamp(),
              players: [...room.players, userId],
              isReady: {...room?.isReady, [userId]: false},
            })

            return room.id
          }
        })
      }
    }
  )
}

export async function getVersusRoom(roomId) {
  return getDocument(doc(db, VERSUS_COLLECTION, roomId))
}

export function listenForRoomChanges(roomId, callback) {
  return onSnapshot(doc(db, VERSUS_COLLECTION, roomId), (doc) => {
    callback({id: doc.id, ...doc.data()})
  })
}

export function listenForVersusCreditChanges(userId, callback) {
  return onSnapshot(doc(db, SETTINGS_COLLECTION, userId), (doc) => {
    callback({id: doc.id, ...doc.data()})
  })
}

export async function updateVersusRoom({roomId, data}) {
  return updateDoc(doc(db, VERSUS_COLLECTION, roomId), {
    ...data,
    updatedOn: serverTimestamp(),
  })
}

// TODO: update below similar functions to above update function and reuse

export async function deleteVersusRoom({roomId, remainingPlayers, isReady}) {
  return updateDoc(doc(db, VERSUS_COLLECTION, roomId), {
    status: VersusRoomStatuses.INACTIVE,
    updatedOn: serverTimestamp(),
    players: remainingPlayers,
    isReady,
  })
}

export async function removePlayerFromVersusRoom({
  roomId,
  remainingPlayers,
  isReady,
}) {
  return updateDoc(doc(db, VERSUS_COLLECTION, roomId), {
    updatedOn: serverTimestamp(),
    players: remainingPlayers,
    isReady,
  })
}

export async function kickPlayerFromVersusRoom({
  roomId,
  remainingPlayers,
  kickedPlayers,
  isReady,
}) {
  return updateDoc(doc(db, VERSUS_COLLECTION, roomId), {
    updatedOn: serverTimestamp(),
    players: remainingPlayers,
    kickedPlayers: kickedPlayers,
    isReady,
  })
}

export async function updatePlayerState({roomId, isReady}) {
  return updateDoc(doc(db, VERSUS_COLLECTION, roomId), {
    updatedOn: serverTimestamp(),
    isReady,
  })
}

export async function getVersusRoomFromLevelId(levelId) {
  const room = query(
    collection(db, VERSUS_COLLECTION),
    where('levelIds', 'array-contains', levelId),
    limit(1)
  )

  return queryDocuments(room).then((arr) => arr[0])
}

// issue: here, if battle has no result, it will be marked finished by cron at the end of the minute, till then it won't show in past battles
// TODO: to fetch such battles, we can change condition to, endDate < current date instead of status = finished
export async function getFinishedVersusRooms(userId) {
  const rooms = query(
    collection(db, VERSUS_COLLECTION),
    where('status', '==', VersusRoomStatuses.FINISHED),
    where('players', 'array-contains', userId)
  )

  return queryDocuments(rooms)
}

export async function getUserSettings(userId) {
  return getDocument(doc(db, SETTINGS_COLLECTION, userId))
}

export async function getTopSubmissionsForUser(userId) {
  function fetchSubmission(submissionId) {
    if (!submissionId) {
      return Promise.resolve(null)
    }
    return getDocument(doc(db, SUBMISSIONS_COLLECTION, submissionId))
  }
  const q = query(
    collection(db, TARGET_SCORES_COLLECTION),
    where('userId', '==', userId),
    where('levelId', '<', 500)
  )
  const scores = await queryDocuments(q)
  // read all submissions for corresponding scores
  let submissions = await Promise.all(
    scores.map((score) => fetchSubmission(score.submissionId))
  )
  submissions = submissions.filter(Boolean)
  return submissions
}

export function saveSubmission(submission) {
  const data = {
    notes: submission.notes,
    isStarred: submission.isStarred,
    isPublic: submission.isPublic,
  }

  return updateDoc(
    doc(db, SUBMISSIONS_COLLECTION, submission.id),
    removeEmptyKeys({
      ...data,
    })
  )
}

export function deleteSubmission(submissionId) {
  return deleteDoc(doc(db, SUBMISSIONS_COLLECTION, submissionId))
}

export async function deleteSubmissions({idsToDelete}) {
  if (idsToDelete.length) {
    const deleteSubmissionsPromises = idsToDelete.map((submissionId) =>
      deleteSubmission(submissionId)
    )

    return Promise.all(deleteSubmissionsPromises)
  }
}
