import React, {useState, useEffect, useContext, useMemo} from 'react'
import {useRouter} from 'next/router'
import Link from 'next/link'
import {Icon} from './Icon'
import {Dropdown} from './Dropdown'
import {GlobalContext, UserContext} from './contexts'
// import {NotificationsBox} from './NotificationsBox'
import {ProBadge} from '@components/ProBadge'
import {
  BattleGameModes,
  battles,
  BattleStatuses,
  getBattleStatus,
} from '@cssbattle/shared/battles'
import {BattleStatus} from './BattleStatus'
import {sendEvent} from './analytics'
import {saveProfile, fetchLevels} from './util/db'
import styles from './Header.module.css'
import {ItemId} from './Common'
import {useWhenMounted} from './hooks/useWhenMounted'
import {HStack, Spacer, VStack} from '@components/Stack'
import {useSubmitKeySymbol} from './hooks/useSubmitKeySymbol'
import {CountDown} from './CountDown'
import {getFormattedDateForDailyTarget, persistAuthUserLocally} from './utils'
import {LivePlayerCounter} from '@components/LivePlayerCounter'
import {StreaksWidget} from '@components/StreaksWidget'
import {motion} from 'framer-motion'
import {Text} from '@components/Text'
import {auth} from './firebase-init'
import {signOut} from 'firebase/auth'
import {isMobile} from './utils'

function getEndDate(battle) {
  return (
    battle.endDate ||
    +new Date(battle.startDate.seconds * 1000) + battle.duration * 60 * 1000
  )
}

function BattleDropdown({battleId, battleName}) {
  return (
    <Dropdown
      dropdownBtnClasses="dropdown-btn--minimal"
      dropdownMenuClasses="dropdown-menu--scrollable"
      isFixed={true}
      onOpen={() => {
        sendEvent({
          name: battleId
            ? 'switchBattleDropdownOpen'
            : 'chooseBattleDropdownOpen',
        })
      }}
      dropdownBtnContent={
        battleId ? (
          <>
            Battle <ItemId id={battleId} />
            {/* <span className="desktop-only"> - {battleName}</span> */}
          </>
        ) : (
          <>
            {/* <Icon name="battle" size="20" /> */}
            <span className="desktop-only">Choose Battle</span>
          </>
        )
      }
    >
      {battles
        .slice(0)
        .reverse()
        .map((battle, index) => {
          return (
            <Link href={`/battle/${battle.id}`} key={battle.id}>
              <a className="dropdown-menu__item">
                #{battle.id} - {battle.name}{' '}
                <BattleStatus status={getBattleStatus(battle)} />
              </a>
            </Link>
          )
        })}
    </Dropdown>
  )
}

function LevelsDropdown({levels, currentLevel}) {
  const submitKeySymbol = useSubmitKeySymbol()

  return (
    <Dropdown
      dropdownBtnClasses="dropdown-btn--minimal"
      dropdownMenuClasses="dropdown-menu--scrollable"
      isFixed={true}
      onOpen={() => {
        sendEvent({name: 'switchTargetDropdownOpen'})
      }}
      dropdownBtnContent={
        <>
          <ItemId id={currentLevel.id} />
          <span className="desktop-only">. {currentLevel.name}</span>
        </>
      }
    >
      <VStack gap="1rem" align="stretch">
        <div>
          {levels.map(({id, name}) => {
            return id ? (
              <Link href={`/play/${id}`} key={id}>
                <a className="dropdown-menu__item">
                  <ItemId id={id} />. {name}
                </a>
              </Link>
            ) : (
              <div
                className="dropdown-menu__item"
                style={{color: 'var(--clr-text-light)'}}
              >
                Locked
              </div>
            )
          })}
        </div>

        <p style={{color: 'var(--clr-text-lightest)'}}>
          Tip: Use {submitKeySymbol}+K for quick navigation
        </p>
      </VStack>
    </Dropdown>
  )
}

function Header({
  levelId,
  level,
  battle,
  battleId,
  battleName,
  onToggleSidebar,
  isSidebarVisible,
}) {
  const user = useContext(UserContext)
  const {theme, setTheme} = useContext(GlobalContext)
  const firstName = user ? (user.displayName || 'User').split(' ')[0] : ''

  // const [battle, setBattle] = useState()
  const [battleLevels, setBattleLevels] = useState()
  const router = useRouter()
  const isLearnPage = router.pathname.match(/learn/)

  const isMounted = useWhenMounted()

  useEffect(() => {
    if (!battle) return
    fetchLevels(battle.levelIds).then(setBattleLevels)
  }, [battle])

  function logoutHandler() {
    const answer = window.confirm('Are you sure you want to logout?')
    if (!answer) {
      return
    }
    window.localStorage.removeItem('lastUser')
    signOut(auth).then(() => {
      window.location.reload()
    })
  }

  function themeChangeHandler() {
    const newTheme = theme === 'dark' ? 'light' : 'dark'
    setTheme(newTheme)

    const savedUser = JSON.parse(
      window.localStorage.getItem('lastUser') || null
    )
    if (savedUser) {
      savedUser.settings = {...savedUser.settings, theme: newTheme}
      persistAuthUserLocally(savedUser)
    }

    sendEvent({name: 'themeChanged', label: newTheme})

    if (user) {
      saveProfile(user.uid, {
        'settings.theme': newTheme,
      })
    }
  }

  const battleStatus = battle ? getBattleStatus(battle) : null

  const isPlayPage = /\/(play\/\S+)|(learn\/\w+\/\d+)/.test(router.asPath)

  const dailyTargetNameLabel = useMemo(() => {
    if (level?.type === 'daily') {
      return getFormattedDateForDailyTarget(level.startDate, true)
    }
    return null
  }, [level?.name])

  return (
    <div className="v2-header">
      <div className="header__left">
        {(isPlayPage || isMobile()) && (
          <HStack>
            {onToggleSidebar && (
              <button
                className="button button--circular"
                onClick={onToggleSidebar}
              >
                {isSidebarVisible ? (
                  <Icon name="cross" marginRight="0" size="20" />
                ) : (
                  <Icon name="menu" marginRight="0" size="24" />
                )}
              </button>
            )}

            <div className={`${styles.headerLogo} desktop-only`}>
              <Link href={'/'}>
                <a className="logo-image  logo-image--full"></a>
              </Link>
            </div>

            {isMobile() && !isPlayPage ? (
              <div className={`${styles.headerLogo} mobile-only`}>
                <Link href={'/'}>
                  <a className="logo-image  logo-image--full"></a>
                </Link>
              </div>
            ) : null}

            {isMobile() && isPlayPage ? (
              <div className={`${styles.headerLogoSmall} mobile-only`}>
                <Link href={'/'}>
                  <a className="logo-image  logo-image--glyph"></a>
                </Link>
              </div>
            ) : null}
          </HStack>
        )}
        <div className={styles.breadcrumbs}>
          {level || battle ? (
            <Link href={level?.type === 'daily' ? '/daily' : '/battles'}>
              <a style={{display: 'block'}}>
                <HStack gap="0.2rem">
                  <Icon
                    name={level?.type === 'daily' ? 'daily-targets' : 'battle'}
                    size="18"
                    marginRight="0"
                    color="var(--clr-text-lightest-final)"
                  />
                  <Text
                    appearance="tertiary"
                    size={1}
                    weight="500"
                    mono={false}
                    classes="desktop-only"
                  >
                    {level?.type === 'daily' ? 'Daily Targets' : 'Battles'}
                  </Text>
                </HStack>
              </a>
            </Link>
          ) : null}

          {!battle && level ? (
            <motion.h2
              className="level"
              initial={{x: -10, opacity: 0}}
              exit={{x: -10, opacity: 0}}
              animate={{x: 0, opacity: 1}}
            >
              {dailyTargetNameLabel || level.name}
            </motion.h2>
          ) : null}

          {battle && level ? (
            <>
              <motion.h2
                className="level"
                initial={{x: -10, opacity: 0}}
                exit={{x: -10, opacity: 0}}
                animate={{x: 0, opacity: 1}}
              >
                <Link href={`/battle/${battle.id}`} key={battle.id}>
                  <a>
                    <span className="desktop-only">{'Battle '}</span>
                    <ItemId id={battle.id} />
                  </a>
                </Link>
              </motion.h2>
              {battleLevels ? (
                <motion.div
                  initial={{x: -10, opacity: 0}}
                  exit={{x: -10, opacity: 0}}
                  animate={{x: 0, opacity: 1, transition: {delay: 0.2}}}
                >
                  <LevelsDropdown levels={battleLevels} currentLevel={level} />
                </motion.div>
              ) : null}
            </>
          ) : null}

          {battle && !level ? (
            <motion.div
              initial={{x: -10, opacity: 0}}
              exit={{x: -10, opacity: 0}}
              animate={{x: 0, opacity: 1}}
            >
              <BattleDropdown battleId={battle.id} battleName={battle.name} />
            </motion.div>
          ) : null}

          {/* {!levelId && !battleId && !isLearnPage ? <BattleDropdown /> : null} */}

          {isLearnPage ? (
            <Link href="/learn">
              <a style={{display: 'block'}} className="desktop-only">
                <HStack gap="0.2rem">
                  <Icon
                    name="learn"
                    size="18"
                    marginRight="0"
                    color="var(--clr-text-lightest-final)"
                  />
                  <Text
                    appearance="tertiary"
                    size={1}
                    weight="500"
                    mono={false}
                  >
                    Learn
                  </Text>
                </HStack>
              </a>
            </Link>
          ) : null}
        </div>
      </div>

      <div>
        {battleStatus === BattleStatuses.ONGOING &&
        battle?.startDate &&
        battle.gameMode === BattleGameModes.FIRST_TO_MATCH ? (
          <div style={{display: 'flex', alignItems: 'center', height: '100%'}}>
            <CountDown
              date={getEndDate(battle)}
              suffix="left"
              onFinish={() => {}}
              minimal={true}
            />
          </div>
        ) : null}
        {!isPlayPage &&
        !isMobile() &&
        battle?.gameMode !== BattleGameModes.FIRST_TO_MATCH ? (
          <StreaksWidget />
        ) : null}
      </div>

      <div className="header__right">
        <Spacer />
        {/* {!isLearnPage && (
          <Link href="/learn">
            <a className="button button--cssbattle desktop-only">Learn CSS</a>
          </Link>
        )} */}
        {/* {!user?.isPro && (
          <Link href="/plus">
            <a className="button--cssbattle desktop-only">
              Be a <ProBadge />
            </a>
          </Link>
        )} */}

        {/* {isMounted && !isLearnPage ? (
          <div style={{display: 'inline-block'}} className="desktop-only">
            <Dropdown
              alignRight={true}
              dropdownMenuClasses="dropdown-menu--text"
              dropdownBtnContent={
                <>
                  <Icon
                    name="help"
                    size="1.2em"
                    marginRight={user ? '0' : '10'}
                  />
                  <span className={`${user ? 'visually-hidden' : ''}`}>
                    How to play
                  </span>
                </>
              }
            >
              <p className="para">
                The objective of the game is to write HTML/CSS to replicate the
                given target image in the least code possible.
              </p>
              <p className="para">
                In the target page, start coding in the editor area on the left.
                As you start typing, the output area in the middle will start
                rendering your code. When you're confident that the output
                matches the target image, hit the <strong>Submit</strong> button
                to see your score.
              </p>
              <h4>Important points to Note:</h4>

              <ul className="unordered-list">
                <li>
                  It is recommended to play on Chromium based browsers
                  (Chrome/Safari) because that is what is used for scoring at
                  the backend. Other browser may have some differences in how
                  they render a particular code.
                </li>
                <li>
                  What you write in the editor, renders as it is. We make no
                  change. This means you don't even get the{' '}
                  <a
                    href="https://www.w3.org/wiki/Doctypes_and_markup_styles"
                    rel="external noopener noreferrer"
                  >
                    <code>DOCTYPE</code>
                  </a>
                </li>
                <li>
                  Since this is "CSS" battle, you are{' '}
                  <strong>
                    not allowed to use JavaScript or images in your code. In
                    fact any external asset is not allowed.
                  </strong>{' '}
                  All code required to generate the target image has to be
                  written in the given editor only.
                </li>
              </ul>

              <p className="para"> Now go and climb the leaderboards!</p>
            </Dropdown>
          </div>
        ) : null} */}

        {/* {user ? <NotificationsBox /> : null} */}

        <LivePlayerCounter />

        <button
          aria-label={theme === 'light' ? 'Dark mode' : 'Light mode'}
          className="button button--circular"
          onClick={themeChangeHandler}
        >
          {theme === 'light' ? (
            <Icon name="moon" marginRight="0" size="24" />
          ) : (
            <Icon name="sun" marginRight="0" size="24" />
          )}
        </button>

        {isMounted && user ? (
          <div className="user-info">
            <Dropdown
              isFixed={true}
              alignRight={true}
              dropdownBtnClasses="dropdown-btn--user"
              dropdownBtnContent={
                <HStack gap="0.3rem" align="center">
                  {user.photoURL ? (
                    <img
                      src={user.photoURL}
                      className="user-info__avatar"
                      alt="User profile"
                    />
                  ) : (
                    <Icon name="user" size="1.75rem" />
                  )}
                  <span className="desktop-only">{firstName}</span>
                  {user.isPro && (
                    <div className="desktop-only d-f">
                      {' '}
                      <ProBadge />{' '}
                    </div>
                  )}
                </HStack>
              }
            >
              {user.isPro ? null : (
                <Link href="/plus">
                  <a className="dropdown-menu__item  highlighted-link">
                    <HStack align="center" gap="0.3rem">
                      <Icon name="sparkle" size="1.25rem" />

                      <HStack align="center" gap="0.5rem">
                        <span>Upgrade to</span>
                        <ProBadge />
                      </HStack>
                    </HStack>
                  </a>
                </Link>
              )}
              <Link href={`/player/${user.username || user.uid}`}>
                <a className="dropdown-menu__item">
                  <HStack align="center" gap="0.3rem">
                    <Icon name="profile" size="1.25rem" />
                    View Profile
                  </HStack>
                </a>
              </Link>
              {!user.username && (
                <Link href="/settings">
                  <a className="dropdown-menu__item">
                    <HStack align="center" gap="0.3rem">
                      <Icon name="profile" size="1.25rem" />
                      Claim a username
                    </HStack>
                  </a>
                </Link>
              )}
              <Link href="/my-battles">
                <a className="dropdown-menu__item">
                  <HStack align="center" gap="0.3rem">
                    <Icon name="battle" size="1.25rem" />
                    My Battles
                  </HStack>
                </a>
              </Link>
              {user ? (
                <Link href="/me">
                  <a className="dropdown-menu__item">
                    <HStack align="center" gap="0.3rem">
                      <Icon name="stats" size="1.25rem" />
                      My Analytics
                    </HStack>
                  </a>
                </Link>
              ) : null}
              <Link href="/settings">
                <a className="dropdown-menu__item">
                  <HStack align="center" gap="0.3rem">
                    <Icon name="gear" size="1.25rem" />
                    Settings
                  </HStack>
                </a>
              </Link>
              <button
                type="button"
                className="dropdown-menu__item"
                onClick={logoutHandler}
              >
                <HStack align="center" gap="0.3rem">
                  <Icon name="logout" size="1.25rem" />
                  Logout
                </HStack>
              </button>
            </Dropdown>
          </div>
        ) : (
          <Link href="/login">
            <a className="button">
              <div className="desktop-only">Sign In / Sign Up</div>
              <span className="mobile-only">Login</span>
            </a>
          </Link>
        )}
      </div>
    </div>
  )
}

export {Header}
